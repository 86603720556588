import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import './css.css';
import {useTelegram} from "../hooks/useTelegram";

function CryptoExchange() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const sendCryptoID = queryParams.get('sendCryptoID');
    const exchangePath = queryParams.get('exchangePath');
    const getCryptoID = queryParams.get('getCryptoID');
    const tg = useTelegram();

    const [title, setTitle] = useState(null);
    const [exchangeData, setExchangeData] = useState({ sendCrypto: null, getCrypto: null });
    const [amountCrypto, setAmountCrypto] = useState(0);
    const [amountUSDT, setAmountUSDT] = useState(0);
    const inputCryptoRef = useRef(null);
    const inputUSDTRef = useRef(null);
    const spanCryptoRef = useRef(null);
    const spanUSDTRef = useRef(null);
    const [amountAfterFee, setAmountAfterFee] = useState(0);
    const [customText, setCustomText] = useState(''); // Добавлено состояние для текста
    const [customTextError, setCustomTextError] = useState(''); // Состояние для ошибки
    const [minAmountError, setMinAmountError] = useState('');


    // Загружаем данные заголовка
    useEffect(() => {
        fetch(`https://api.just-trade.ru/api/api/exchangeData?currencies=btc,usdt&exchangePath=${exchangePath}`)
            .then(response => response.json())
            .then(data => {
                setTitle(data);
            })
            .catch(error => console.error('Ошибка при получении данных:', error));
    }, []);

    const handleBackClick = () => {
        window.location.href = window.location.origin; // Переход на just-trade.ru
    };
    
    useEffect(() => {
        if (tg.BackButton) {
            // Показываем кнопку "Назад" в Telegram WebApp
            tg.BackButton.show();

            // Устанавливаем обработчик клика на кнопку
            tg.BackButton.onClick(handleBackClick);
        }
    }, [tg]);

    // Загружаем данные о криптовалютах асинхронно
    const loadCryptoData = async (cryptoID, type) => {
        try {
            const response = await fetch(`https://api.just-trade.ru/api/crypto/${cryptoID}`);
            if (!response.ok) throw new Error('Криптовалюта не найдена');
            
            const data = await response.json();
            const { name, image_url, rate, symbol } = data;

            setExchangeData(prevData => ({
                ...prevData,
                [type]: {
                    name,
                    url: `https://api.just-trade.ru/api/public/${image_url}`,
                    rate: rate || 0,
                    symbol: symbol
                }
            }));

        } catch (error) {
            console.error('Ошибка при получении данных:', error);
        }
    };

    // Загрузка отправляемой и получаемой криптовалюты и расчет суммы
    useEffect(() => {
        const fetchCryptoData = async () => {
            await Promise.all([
                loadCryptoData(sendCryptoID, 'sendCrypto'),
                loadCryptoData(getCryptoID, 'getCrypto')
            ]);

            if (exchangeData.sendCrypto?.rate && exchangeData.getCrypto?.rate) {
                const conversionRate = exchangeData.sendCrypto.rate / exchangeData.getCrypto.rate;
                setAmountUSDT(amountCrypto * conversionRate);
            }
        };

        if (sendCryptoID && getCryptoID) {
            fetchCryptoData();
        }
    }, [sendCryptoID, getCryptoID, amountCrypto]);

    useEffect(() => {
        document.body.style.backgroundColor = '#313B43';
    }, []);

    const calculateAmountWithRounding = (amount, rate) => {
        let result = amount * rate;
    
        // Логика округления в зависимости от курса
        if (rate > 1000) {
            result = result.toFixed(8);  // Округляем до 8 знаков, если курс > 1000$
        } else if (rate > 100) {
            result = result.toFixed(5);  // Округляем до 5 знаков, если курс > 100$
        } else {
            result = result.toFixed(2);  // По умолчанию округляем до 2 знаков
        }
    
        return parseFloat(result);  // Возвращаем число
    };

    const calculateFee = (amount, path) => {
        let feePercentage = 0;

        if (path.endsWith('CryptoBot')) {
            feePercentage = 9; // 6% комиссия для CryptoBot
        } else if (path.endsWith('xRocket')) {
            feePercentage = 7.5; // 4.5% комиссия для xRocket
        } else if (path.endsWith('externalWallet')) {
            feePercentage = 9; // 4.5% комиссия для Внешнего
        }

        return amount * (1 - feePercentage / 100);
    };

    const handleCryptoChange = (e) => {
        let value = e.target.value;
    
        // Если введена только точка или запятая, преобразуем в "0."
        if (value === '.' || value === ',') {
            value = '0.';
        }
    
        // Заменяем запятую на точку для унификации
        if (value.includes(',')) {
            value = value.replace(',', '.');
        }
    
        // Убираем ведущий ноль, если его нет после точки
        if (value.startsWith('0') && !value.includes('.')) {
            value = value.replace(/^0+/, ''); // Убираем все ведущие нули
        }
    
        // Проверяем, является ли значение числом
        const numericValue = parseFloat(value);
    
        if (!isNaN(numericValue) && /^[0-9]*\.?[0-9]*$/.test(value)) {
            // Если значение валидное, обновляем состояние
            setAmountCrypto(value);
    
            if (exchangeData.sendCrypto?.rate && exchangeData.getCrypto?.rate) {
                const conversionRate = exchangeData.sendCrypto.rate / exchangeData.getCrypto.rate;
                const newAmountUSDT = parseFloat(numericValue * conversionRate);
                setAmountUSDT(newAmountUSDT);
    
                // Применяем комиссию к получаемой сумме
                let adjustedAmount = calculateFee(newAmountUSDT, exchangePath);
                if (exchangeData.getCrypto.rate >= 1000) {
                    adjustedAmount = parseFloat(adjustedAmount.toFixed(8)); // Округляем до 8 знаков и преобразуем обратно в число
                } else if (exchangeData.getCrypto.rate >= 100) {
                    adjustedAmount = parseFloat(adjustedAmount.toFixed(5)); // Округляем до 5 знаков и преобразуем обратно в число
                } else {
                    adjustedAmount = parseFloat(adjustedAmount.toFixed(2)); // По умолчанию округляем до 2 знаков
                }
                setAmountAfterFee(adjustedAmount);
                console.log(adjustedAmount);
            }
        } else {
            // Если значение не валидное, сбрасываем на "0"
            setAmountCrypto(0);
            setAmountUSDT(0);
            setAmountAfterFee(0);
        }
    
        adjustInputWidth(value, inputCryptoRef, spanCryptoRef);
    };
    
    const handleUSDTChange = (e) => {
        let value = e.target.value;
    
        // Разрешаем запятую как десятичный разделитель
        if (value.includes(',')) {
            value = value.replace(',', '.');  // Заменяем запятую на точку
        }
    
        // Преобразуем значение в число
        const numericValue = parseFloat(value);
    
        if (!isNaN(numericValue)) {  // Проверяем, является ли значение числом
            setAmountUSDT(numericValue);
    
            if (exchangeData.sendCrypto?.rate && exchangeData.getCrypto?.rate) {
                const conversionRate = exchangeData.sendCrypto.rate / exchangeData.getCrypto.rate;
                const newAmountCrypto = numericValue / conversionRate;
                setAmountCrypto(newAmountCrypto);
            }
    
            // Применяем комиссию к получаемой сумме
            const adjustedAmount = calculateFee(numericValue, exchangePath);
            setAmountAfterFee(adjustedAmount);
        } else {
            setAmountUSDT(0);  // Если значение не число, сбрасываем его
        }
    
        adjustInputWidth(value, inputUSDTRef, spanUSDTRef);
    };
    
    

    const adjustInputWidth = (value, inputRef, spanRef) => {
        if (spanRef.current && inputRef.current) {
            spanRef.current.textContent = value;
            const spanWidth = spanRef.current.offsetWidth;
            inputRef.current.style.width = `${spanWidth + 15}px`;
        }
    };

    
    useEffect(() => {
        adjustInputWidth(amountCrypto, inputCryptoRef, spanCryptoRef);
        adjustInputWidth(amountAfterFee, inputUSDTRef, spanUSDTRef);
    }, [exchangeData]);

    const handlePayment = async () => {
        if (exchangePath.endsWith('CryptoBot')) {
            const response = await fetch(`https://api.just-trade.ru/api/crypto/${sendCryptoID}`);
            if (!response.ok) throw new Error('Криптовалюта не найдена');
            const data = await response.json();
            const { rate } = data;

            // Вычисляем минимальное количество криптовалюты
            const minCryptoAmount = 1.1 / rate;

            // Проверяем, что введенное количество больше минимального
            if (amountCrypto < minCryptoAmount) {
                setMinAmountError(
                    `Минимальная сумма обмена составляет ${minCryptoAmount.toFixed(6)} ${exchangeData.sendCrypto?.symbol || ''}`
                );
                return; // Прекращаем выполнение, если сумма меньше минимальной
            } else {
                setMinAmountError(''); // Очищаем ошибку, если сумма достаточна
            }
        }

        if (exchangePath.endsWith('externalWallet')) {
            const response = await fetch(`https://api.just-trade.ru/api/crypto/${sendCryptoID}`);
            if (!response.ok) throw new Error('Криптовалюта не найдена');
            const data = await response.json();
            const { rate } = data;

            // Вычисляем минимальное количество криптовалюты
            const minCryptoAmount = 2 / rate;

            // Проверяем, что введенное количество больше минимального
            if (amountCrypto < minCryptoAmount) {
                setMinAmountError(
                    `Минимальная сумма обмена составляет ${minCryptoAmount.toFixed(6)} ${exchangeData.sendCrypto?.symbol || ''}`
                );
                return; // Прекращаем выполнение, если сумма меньше минимальной
            } else {
                setMinAmountError(''); // Очищаем ошибку, если сумма достаточна
            }
        }
        
        if (exchangePath.includes('externalWallet') && !customText.trim()) {
            setCustomTextError('Пожалуйста, введите адрес внешнего кошелька');  // Устанавливаем ошибку
            return;  // Прекращаем выполнение, если поле не заполнено
        } else {
            setCustomTextError('');  // Очищаем ошибку, если текст введен
        }
        const payload = {
            asset: exchangeData.sendCrypto.symbol.toUpperCase(),
            amount: amountCrypto,
            description: "Оплата",
            user_id: tg.user.id,
            path: exchangePath,
            wannaRecive: exchangeData.getCrypto.name,
            externalWallet: customText,
        };
    
        // Определение URL API в зависимости от exchangePath
        let apiUrl;
        if (exchangePath.startsWith('CryptoBot')) {
            apiUrl = 'https://api.just-trade.ru/api/createInvoiceCryptoBot';  // Замените на правильный URL для CryptoBot
        } else if (exchangePath.startsWith('xRocket')) {
            apiUrl = 'https://api.just-trade.ru/api/createInvoicexRocket';
        } else {
            console.error('Неподдерживаемый exchangePath:', exchangePath);
            return; // Прекращаем выполнение, если exchangePath неподдерживаемый
        }
    
        fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        })
            .then(response => response.json())
            .then(data => {
                if (data.error) {
                    console.error(data.error);
                } else {
                    tg.openTelegramLink(data.link);
                }
            })
            .catch(error => console.error('Ошибка при создании счета:', error));
    };

    const handleFocus = () => {
        setAmountCrypto(''); // При фокусе сбрасываем значение в 0
        setAmountUSDT(0); // Сбрасываем сумму в USDT тоже
        setAmountAfterFee(0); // Сбрасываем сумму после комиссии
    };


    return (
        <div className="containerEXCHNAGER">
            {exchangeData.sendCrypto && exchangeData.getCrypto && (
                <div className="exchangeBox">
                    <h3 className="title" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <img src={title?.title?.images?.first} alt={title?.title?.text?.first} style={{ width: '30px', marginRight: '5px' }} />
                        <span>{title?.title?.text?.first} ↔️ {title?.title?.text?.second}</span>
                        <img src={title?.title?.images?.second} alt={title?.title?.text?.second} style={{ width: '30px', marginLeft: '5px' }} />
                    </h3>
                    <div className="inputGroup">
                        <label className="label">Отправляете</label>
                        <div className="inputRow">
                            <div className="cryptoInfo">
                                <a href={`/crypto-details?exchangePath=${exchangePath}&getCryptoID=${getCryptoID}`} rel="noopener noreferrer" className="cryptoLink">
                                    <img src={exchangeData.sendCrypto.url} alt={sendCryptoID} className="iconImage" />
                                    <span className="cryptoName">{exchangeData.sendCrypto.name}</span>
                                </a>
                            </div>
                            <input
                                type="text"
                                value={amountCrypto}
                                onFocus={handleFocus}
                                onChange={handleCryptoChange}
                                className="inputCrypto"
                                ref={inputCryptoRef}
                                style={{ minWidth: '80px' }}
                            />
                            <span ref={spanCryptoRef} className="hidden-span"></span>
                        </div>
                        {minAmountError && <span className="error-text">{minAmountError}</span>}
                    </div>
                    <div className="outputGroup">
                        <label className="label">Получаете</label>
                        <div className="inputRow">
                            <div className="cryptoInfo">
                                <a href={`/crypto-details?exchangePath=${exchangePath}&sendCryptoID=${sendCryptoID}`} rel="noopener noreferrer" className="cryptoLink">
                                    <img src={exchangeData.getCrypto.url} alt={getCryptoID} className="iconImage" />
                                    <span className="cryptoName">{exchangeData.getCrypto.name}</span>
                                </a>
                            </div>
                            {/* Убираем возможность изменять поле для USDT, делаем его только для чтения */}
                            <input
                                type="text"
                                value={amountAfterFee}
                                readOnly
                                className="inputUSDT"
                                ref={inputUSDTRef}
                                style={{ minWidth: '80px' }}
                            />
                            <span ref={spanUSDTRef} className="hidden-span"></span>
                        </div>
                    </div>
                    {exchangePath.includes('externalWallet') && (
                        <div className="inputGroup">
                            <label className="label" htmlFor="customTextInput">Адрес внешнего кошелька</label>
                            <textarea
                                id="customTextInput"
                                value={customText}
                                onChange={(e) => setCustomText(e.target.value)}
                                placeholder="Введите адрес кошелька"
                                className="textAreaCustom"
                            />
                            {customTextError && <span className="error-text">{customTextError}</span>} {/* Показываем ошибку */}
                        </div>
                    )}
                </div>
            )}
            {/* Back button */}
            {/* <button className="back-buttons" onClick={handleBackClick}>
                Вернутся в главное меню
            </button> */}
            <div className="buttonContainer">
                <button className="button" onClick={handlePayment}>
                    ОПЛАТИТЬ
                </button>
            </div>
        </div>
    );
}

export default CryptoExchange;
