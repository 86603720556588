import React from 'react';
import './controlMenu.css'; // Подключаем стили

import handshakeIcon from './icons/ref.png';
import exchangeIcon from './icons/trade.png';
import historyIcon from './icons/clock.png';

const FooterMenu = () => {
    return (
        <div className="footer-menu">
            <a href="https://just-trade.ru/ref" className="footer-button" rel="noopener noreferrer">
                <img src={handshakeIcon} alt="Referral" className="footer-icon" />
            </a>
            <a href="https://just-trade.ru" className="footer-button" rel="noopener noreferrer">
                <img src={exchangeIcon} alt="Home" className="footer-icon" />
            </a>
            <a href="https://just-trade.ru/history" className="footer-button" rel="noopener noreferrer">
                <img src={historyIcon} alt="History" className="footer-icon" />
            </a>
        </div>
    );
};

export default FooterMenu;
