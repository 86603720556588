// src/App.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import HomePage from './components/mainPage/mainPage';
import CryptoDetailsPage from './components/CryptoDetailsPage/CryptoDetailsPage';
import ExchangePage from './components/exchangePage/page';
import { useTelegram } from './components/hooks/useTelegram';
import RefPage from './components/refSystemPage/page';
import History from './components/transactionHisroty/history';

const App = () => {
  const {tg, onToggleButton} = useTelegram()

  useEffect( () => {
    tg.ready();
  }, [])

  return (
    <Router>
      <div className="App">
        <Routes>
          {/* Главная страница */}
          <Route path="/" element={<HomePage />} />

          <Route path="/ref" element={<RefPage />} />

          <Route path="/crypto-details" element={<CryptoDetailsPage />} />

          <Route path="/exchange" element={<ExchangePage />} />

          <Route path="/history" element={<History />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
